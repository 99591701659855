import { topicsQuery } from 'core/queries/topics';
import useRequest from 'core/hooks/useRequest';
import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import CardExpert from 'site/cards/CardExpert';
import CardMom from 'site/cards/CardMom';
import CardPhoto from 'site/cards/CardPhoto';
import { filterRequiredParams, denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';
import { bebop as bebopApi } from 'core/api/definitions/bebop';
import { VOID_QUERY } from 'core/site/constants';

const filteredFields = filterRequiredParams([Card1, Card2], 'fields');


export default function useMainContent() {
  const results = useRequest({ queries: [
    topicsQuery({
      card: Card2,
      limit: 7,
      topic_type: 'news',
      visibility: 'main_page',
      renderError: () => [],
    }),
    topicsQuery({
      card: CardExpert,
      limit: 8,
      tag: 'lichnyy-opyt',
      visibility: 'main_page',
      renderError: () => [],
    }),
    topicsQuery({
      card: CardMom,
      limit: 8,
      tag: 'roditeli-o-vazhnom',
      plugin: '{"opinion": 1}',
      visibility: 'main_page',
      renderError: () => [],
    }),
    topicsQuery({
      card: CardExpert,
      limit: 8,
      tag: 'mneniye-ekspertov',
      visibility: 'main_page',
      renderError: () => [],
    }),
  ] });

  const {
    data: mainArticles,
    isLoading: mainArticlesAreLoading,
  } = useOtherTopics({
    list: 'main',
    topicType: 'article,video,gallery',
    limit: 7,
    include: 'image,rubric',
    fields: filteredFields,
  });

  const {
    data: photoTopics,
    isLoading: photoTopicsAreLoading,
  } = useOtherTopics({
    list: 'photo',
    topicType: 'gallery',
    include: 'image,photo_gallery,rubric',
    fields: filterRequiredParams([Card2, CardPhoto], 'fields'),
    limit: 4,
  });

  const {
    data: videoTopics,
    isLoading: videoTopicsAreLoading,
  } = useOtherTopics({
    list: 'video',
    topicType: 'video',
    include: 'image,rubric',
    fields: filteredFields,
    limit: 4,
  });

  const {
    data: [
      firstBlockTopics,
      personalExperienceTopics,
      parentsTopics,
      expertsTopics,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (resultsAreLoading || mainArticlesAreLoading || photoTopicsAreLoading || videoTopicsAreLoading) {
    return { isLoading: true };
  }

  return {
    firstBlockTopics,
    personalExperienceTopics,
    parentsTopics,
    expertsTopics,
    mainArticles,
    photoTopics,
    videoTopics,
  };
}

function useOtherTopics({ list, topicType, limit, include, fields }) {
  const { data: first, isLoading: isFirstLoading } = useRequest({
    queryKey: ['bebopApi', 'getTopics', {
      list,
      sort: 'list',
      limit,
      include,
      fields,
      visibility: 'main_page',
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    select: denormalizeData,
  });

  const queryParams = !isFirstLoading && (first?.length || 0) < limit
    ? {
      queryKey: ['bebopApi', 'getTopics', {
        topic_type: topicType,
        limit: limit - (first?.length || 0),
        include,
        fields,
        visibility: 'main_page',
      }],
      queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
      select: denormalizeData,
    }
    : VOID_QUERY;

  const { data: second, isLoading: isSecondLoading } = useRequest(queryParams);
  const combinedData = [
    ...(first || []),
    ...(second || []),
  ];
  const isLoading = isFirstLoading || isSecondLoading;

  return { data: combinedData, isLoading };
}
